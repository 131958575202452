<template>
  <!--  <div class="bg_radian_gradient"></div>-->
  <div class="background-image">
    <div class="contacts_block">
      <a href="https://vk.com/pavel_g_s" target="_blank">
        <svg class="sm" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25ZM26.3078 33.2043C26.3078 33.2043 26.7891 33.1518 27.0355 32.8919C27.2611 32.6537 27.2533 32.2044 27.2533 32.2044C27.2533 32.2044 27.2233 30.106 28.2159 29.7961C29.1942 29.4915 30.4501 31.8254 31.7831 32.7229C32.79 33.4015 33.5543 33.2529 33.5543 33.2529L37.1162 33.2043C37.1162 33.2043 38.9787 33.0916 38.0957 31.6539C38.0227 31.536 37.5805 30.59 35.4481 28.6465C33.2139 26.6122 33.5139 26.9411 36.2032 23.4218C37.8415 21.2785 38.4961 19.9701 38.2914 19.4107C38.0971 18.8755 36.8919 19.0176 36.8919 19.0176L32.8826 19.042C32.8826 19.042 32.5852 19.0023 32.3648 19.1316C32.1496 19.2583 32.01 19.5541 32.01 19.5541C32.01 19.5541 31.3761 21.212 30.5297 22.6228C28.7442 25.5982 28.0307 25.7556 27.7385 25.5714C27.0591 25.1399 27.2285 23.8404 27.2285 22.9173C27.2285 20.0329 27.6747 18.8307 26.3612 18.5196C25.9256 18.4159 25.6048 18.348 24.4896 18.3366C23.0589 18.3225 21.8484 18.3417 21.1624 18.6707C20.7059 18.8896 20.3538 19.3786 20.569 19.4069C20.8337 19.4414 21.4338 19.5656 21.752 19.9906C22.1628 20.5399 22.1484 21.7715 22.1484 21.7715C22.1484 21.7715 22.3846 25.1668 21.5968 25.5879C21.0568 25.8773 20.3159 25.2871 18.7234 22.587C17.9083 21.2044 17.2927 19.6757 17.2927 19.6757C17.2927 19.6757 17.174 19.3902 16.9614 19.2366C16.7045 19.0509 16.3457 18.9933 16.3457 18.9933L12.536 19.0176C12.536 19.0176 11.9634 19.033 11.7534 19.2775C11.567 19.4939 11.7391 19.9432 11.7391 19.9432C11.7391 19.9432 14.722 26.794 18.1 30.2469C21.1976 33.4117 24.714 33.2043 24.714 33.2043H26.3078Z"
                fill="#7d65c9"/>
        </svg>
      </a>

      <a href="https://t.me/pavelgs0" target="_blank">
        <svg class="sm" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM16.7411 22.7099C14.2368 23.8033 11.6636 24.9269 9.31068 26.2229C8.08205 27.1225 9.71498 27.7589 11.247 28.3557C11.4905 28.4506 11.7315 28.5445 11.958 28.6382C12.1466 28.6962 12.3382 28.7573 12.5325 28.8192C14.2364 29.362 16.1361 29.9673 17.7903 29.0568C20.5076 27.4958 23.0719 25.6912 25.6343 23.888C26.4737 23.2972 27.313 22.7066 28.1574 22.1247C28.197 22.0994 28.2416 22.0704 28.2904 22.0389C29.0097 21.5725 30.6275 20.5239 30.0291 21.9689C28.6141 23.5164 27.0983 24.8861 25.5745 26.2635C24.5474 27.1918 23.5166 28.1234 22.5103 29.1151C21.634 29.8272 20.7239 31.2592 21.7052 32.2564C23.9655 33.8386 26.2611 35.3826 28.5554 36.9257C29.302 37.4278 30.0485 37.9299 30.7935 38.4332C32.0562 39.4414 34.0297 38.6258 34.3073 37.0505C34.4307 36.3258 34.5547 35.6012 34.6786 34.8766C35.3636 30.8717 36.0489 26.8653 36.6542 22.8474C36.7365 22.2171 36.8297 21.5869 36.923 20.9564C37.1492 19.4281 37.3756 17.898 37.4464 16.3616C37.2641 14.8282 35.4049 15.1654 34.3703 15.5102C29.0524 17.5337 23.7876 19.7073 18.5436 21.9192C17.9496 22.1823 17.3474 22.4452 16.7411 22.7099Z"
                fill="#7d65c9"/>
        </svg>
      </a>

      <a href="https://github.com/pavelglazunov" target="_blank">
        <svg class="sm" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M25.0002 0C11.1948 0 0 11.4756 0 25.6327C0 36.9582 7.16332 46.5665 17.0966 49.9559C18.3461 50.1933 18.8048 49.3999 18.8048 48.7228C18.8048 48.1116 18.7816 46.0924 18.7709 43.9505C11.8158 45.5011 10.3481 40.9261 10.3481 40.9261C9.21093 37.9633 7.57234 37.1754 7.57234 37.1754C5.30407 35.5845 7.74333 35.6172 7.74333 35.6172C10.2538 35.7977 11.5756 38.2587 11.5756 38.2587C13.8054 42.1775 17.4241 41.0445 18.8507 40.3895C19.0751 38.7333 19.7231 37.6016 20.438 36.962C14.8851 36.3138 9.04782 34.1158 9.04782 24.2939C9.04782 21.4953 10.0244 19.2087 11.6236 17.4136C11.3641 16.7675 10.5083 14.1609 11.8659 10.6301C11.8659 10.6301 13.9652 9.94119 18.7427 13.2576C20.7369 12.6897 22.8756 12.4048 25.0002 12.3951C27.1249 12.4048 29.2652 12.6897 31.2631 13.2576C36.0348 9.94119 38.1313 10.6301 38.1313 10.6301C39.492 14.1609 38.6358 16.7675 38.3763 17.4136C39.9793 19.2087 40.9493 21.4953 40.9493 24.2939C40.9493 34.1392 35.1008 36.307 29.5338 36.9416C30.4306 37.737 31.2296 39.297 31.2296 41.6885C31.2296 45.1178 31.2006 47.8781 31.2006 48.7228C31.2006 49.405 31.6506 50.2042 32.9178 49.9526C42.8458 46.5593 50 36.9543 50 25.6327C50 11.4756 38.8069 0 25.0002 0Z"
                fill="#7d65c9"/>
        </svg>
      </a>

      <!--      <svg xmlns="http://www.w3.org/2000/svg" width="206" height="50" viewBox="0 0 206 50" fill="none">-->
      <!--        <path fill-rule="evenodd" clip-rule="evenodd" d="M181 0C167.195 0 156 11.4756 156 25.6327C156 36.9582 163.163 46.5665 173.097 49.9559C174.346 50.1933 174.805 49.3999 174.805 48.7228C174.805 48.1116 174.782 46.0924 174.771 43.9505C167.816 45.5011 166.348 40.9261 166.348 40.9261C165.211 37.9633 163.572 37.1754 163.572 37.1754C161.304 35.5845 163.743 35.6172 163.743 35.6172C166.254 35.7977 167.576 38.2587 167.576 38.2587C169.805 42.1775 173.424 41.0445 174.851 40.3895C175.075 38.7333 175.723 37.6016 176.438 36.962C170.885 36.3138 165.048 34.1158 165.048 24.2939C165.048 21.4953 166.024 19.2087 167.624 17.4136C167.364 16.7675 166.508 14.1609 167.866 10.6301C167.866 10.6301 169.965 9.94119 174.743 13.2576C176.737 12.6897 178.876 12.4048 181 12.3951C183.125 12.4048 185.265 12.6897 187.263 13.2576C192.035 9.94119 194.131 10.6301 194.131 10.6301C195.492 14.1609 194.636 16.7675 194.376 17.4136C195.979 19.2087 196.949 21.4953 196.949 24.2939C196.949 34.1392 191.101 36.307 185.534 36.9416C186.431 37.737 187.23 39.297 187.23 41.6885C187.23 45.1178 187.201 47.8781 187.201 48.7228C187.201 49.405 187.651 50.2042 188.918 49.9526C198.846 46.5593 206 36.9543 206 25.6327C206 11.4756 194.807 0 181 0Z" fill="black"/>-->
      <!--        <path fill-rule="evenodd" clip-rule="evenodd" d="M128 25C128 38.8071 116.807 50 103 50C89.1929 50 78 38.8071 78 25C78 11.1929 89.1929 0 103 0C116.807 0 128 11.1929 128 25ZM94.7411 22.7099C92.2368 23.8033 89.6636 24.9269 87.3107 26.2229C86.0821 27.1225 87.715 27.7589 89.247 28.3557C89.4905 28.4506 89.7315 28.5445 89.958 28.6382C90.1466 28.6962 90.3382 28.7573 90.5325 28.8192C92.2364 29.362 94.1361 29.9673 95.7903 29.0568C98.5076 27.4958 101.072 25.6912 103.634 23.888C104.474 23.2972 105.313 22.7066 106.157 22.1247C106.197 22.0994 106.242 22.0704 106.29 22.0389C107.01 21.5725 108.627 20.5239 108.029 21.9689C106.614 23.5164 105.098 24.8861 103.574 26.2635C102.547 27.1918 101.517 28.1234 100.51 29.1151C99.634 29.8272 98.7239 31.2592 99.7052 32.2564C101.966 33.8386 104.261 35.3826 106.555 36.9257C107.302 37.4278 108.049 37.9299 108.794 38.4332C110.056 39.4414 112.03 38.6258 112.307 37.0505C112.431 36.3258 112.555 35.6012 112.679 34.8766C113.364 30.8717 114.049 26.8653 114.654 22.8474C114.736 22.2171 114.83 21.5869 114.923 20.9564C115.149 19.4281 115.376 17.898 115.446 16.3616C115.264 14.8282 113.405 15.1654 112.37 15.5102C107.052 17.5337 101.788 19.7073 96.5436 21.9192C95.9496 22.1823 95.3474 22.4452 94.7411 22.7099Z" fill="black"/>-->
      <!--        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25ZM26.3078 33.2043C26.3078 33.2043 26.7891 33.1518 27.0355 32.8919C27.2611 32.6537 27.2533 32.2044 27.2533 32.2044C27.2533 32.2044 27.2233 30.106 28.2159 29.7961C29.1942 29.4915 30.4501 31.8254 31.7831 32.7229C32.79 33.4015 33.5543 33.2529 33.5543 33.2529L37.1162 33.2043C37.1162 33.2043 38.9787 33.0916 38.0957 31.6539C38.0227 31.536 37.5805 30.59 35.4481 28.6465C33.2139 26.6122 33.5139 26.9411 36.2032 23.4218C37.8415 21.2785 38.4961 19.9701 38.2914 19.4107C38.0971 18.8755 36.8919 19.0176 36.8919 19.0176L32.8826 19.042C32.8826 19.042 32.5852 19.0023 32.3648 19.1316C32.1496 19.2583 32.01 19.5541 32.01 19.5541C32.01 19.5541 31.3761 21.212 30.5297 22.6228C28.7442 25.5982 28.0307 25.7556 27.7385 25.5714C27.0591 25.1399 27.2285 23.8404 27.2285 22.9173C27.2285 20.0329 27.6747 18.8307 26.3612 18.5196C25.9256 18.4159 25.6048 18.348 24.4896 18.3366C23.0589 18.3225 21.8484 18.3417 21.1624 18.6707C20.7059 18.8896 20.3538 19.3786 20.569 19.4069C20.8337 19.4414 21.4338 19.5656 21.752 19.9906C22.1628 20.5399 22.1484 21.7715 22.1484 21.7715C22.1484 21.7715 22.3846 25.1668 21.5968 25.5879C21.0568 25.8773 20.3159 25.2871 18.7234 22.587C17.9083 21.2044 17.2927 19.6757 17.2927 19.6757C17.2927 19.6757 17.174 19.3902 16.9614 19.2366C16.7045 19.0509 16.3457 18.9933 16.3457 18.9933L12.536 19.0176C12.536 19.0176 11.9634 19.033 11.7534 19.2775C11.567 19.4939 11.7391 19.9432 11.7391 19.9432C11.7391 19.9432 14.722 26.794 18.1 30.2469C21.1976 33.4117 24.714 33.2043 24.714 33.2043H26.3078Z" fill="black"/>-->
      <!--      </svg>-->
    </div>
    <div class="main_block_texts">
      <typing-effect/>
      <p class="main_small_text" id="typing_small_text"></p>
    </div>
  </div>
  <section class="section_about">
    <div class="carousel_block">
      <CarouselImage/>

    </div>
    <div class="about_text">
      <TitleText text="обо мне"/>
      <ul class="text_list">
        <li class="li_text">Возраст: 18 лет</li>
        <li class="li_text">Опыт в IT: Лицей академии Яндекс, олимпиада по промышленному программированию PROD</li>
        <li class="li_text">ЯП: python, Golang</li>
        <li class="li_text">Город: Москва</li>
        <li class="li_text">Школа: ГБОУ школа №1580 при МГТУ им. Баумана</li>
      </ul>
    </div>

  </section>
  <section class="section_projects">
    <TitleText text="проекты"/>
    <div class="tabs">
      <MyTab @click="updateActiveTab" tab_id="websites" tab_name="сайты" tab_checked/>
      <MyTab @click="updateActiveTab" tab_id="bots" tab_name="боты"/>
      <MyTab @click="updateActiveTab" tab_id="libs" tab_name="библиотеки"/>
      <MyTab @click="updateActiveTab" tab_id="utils" tab_name="утилиты"/>
      <MyTab @click="updateActiveTab" tab_id="games" tab_name="игры"/>
    </div>
    <div class="tab_content">
      <ProjectCard
          v-for="(card, card_id) of cards"
          :key="card_id"
          :type="card.type"
          :selected_tab="this.activeTab"
          :header="card.project_name"
          :description="card.description"
          :url="card.url"
          :code="card.code"
          :special_text="card.special_text"
          :github="card.github"

      />

    </div>
  </section>

  <!--  <div class="overflow_block">-->

  <!--    <div class="image_block">-->
  <!--      <div class="image"></div>-->
  <!--&lt;!&ndash;      <img alt="bg" src="./assets/bg.jpg" class="image">&ndash;&gt;-->

  <!--    </div>-->


  <!--  </div>-->

  <!--    <h1>Pavel</h1>-->
  <!--    <h1>Glazunov</h1>-->
</template>

<script>
// import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
// import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
// import HelloWorld from './components/HelloWorld.vue'
// import ProjectCard from "@/components/card";
// import TitleText from "@/components/title";
// import MyTab from "@/components/tab";

import CarouselImage from "@/components/CarouselImages";
import TitleText from "@/components/title";
import MyTab from "@/components/tab";
import ProjectCard from "@/components/card";
import TypingEffect from "@/components/TypingEffect";
import {ALL_PROJECTS} from "@/assets/projects"

console.log(ALL_PROJECTS)

export default {
  name: 'App',
  components: {ProjectCard, MyTab, TitleText, CarouselImage, TypingEffect},
  data() {
    return {
      "activeTab": 'websites',
      "question_text": null,
      "all_cards": ALL_PROJECTS,
      "cards": [],
    }
  },
  methods: {
    updateActiveTab(tabId) {
      this.cards = [];
      for (const card of this.all_cards) {
        if (card.type === tabId.target.id) {
          this.cards.push(card);
        }
      }

    }
  },

  async mounted() {
    this.updateActiveTab({"target": {"id": "websites"}})
  }
}


// setTimeout(typeWriter, 500)


</script>

<style src="./css/tabs.css"></style>
<style>

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500;600;800&display=swap');

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

  background: linear-gradient(196deg, #150821 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(96deg, #04051E 0%, #1A172C 100%);
  min-height: 100vh;


}

.bg_radian_gradient {
  position: absolute;
  border-radius: 1085px;

  top: 87vw;
  left: 70vw;
  /*height: 150vh;*/

  width: 896px;
  height: 1085px;
  flex-shrink: 0;

  background: radial-gradient(44.04% 44.04% at 50.00% 50.00%, rgba(235, 37, 86, 0.26) 0%, rgba(235, 37, 86, 0.00) 100%);
}

.background-image {
  background-image: url('./assets/bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  width: 100%;
  min-height: 100vh;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section_projects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.contacts_block {

  width: 100%;

  display: flex;
  justify-content: end;
  /*padding: 4vh;*/

}

.section_about {
  margin-top: 7vh;

  display: flex;
  justify-content: space-around;
}

.carousel_block {
  width: 30vw;
  height: 20vw;

  margin-bottom: 10vh;

}

.text_list {
  color: #FFF;
  font-family: 'Source Code Pro', monospace;

  font-size: 3vh;

  text-align: start;
  list-style: inside;

  margin-bottom: 8vh;
}

.li_text:hover {
  filter: drop-shadow(0px 6px 6px rgba(213, 60, 60, 0.25));

}

.about_text {
  width: 30vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

@media screen and (max-width: 1000px) {

  .text_list {
    font-size: 2vh;
  }

  .about_text {
    width: 95vw;
  }

  .section_about {
    margin-top: 3vh;

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;

    align-items: center;
  }

  .carousel_block {
    width: 80vw;
    height: 58vw;
  }

}

.sm {
  margin-right: 2vh;
  margin-top: 2vh;
}

.main_block_texts {

  text-align: start;
  /*margin-top: 20vh;*/

  /*position: relative;*/
  color: #FFF;
  font-family: 'Source Code Pro', monospace;

  /*max-width: 20vw;*/
  width: 96vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 10vh;
  margin-left: 4vw;

  z-index: 2;
}


</style>
